import React, { Component, createRef } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ActionCreators } from "./actions/profile";
import { _callApi } from "./Services/baseService";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-utilities.css";
import "bootstrap/dist/css/bootstrap-reboot.css";
import "bootstrap/dist/css/bootstrap-grid.css";
import "./index.css";
import Loader from "react-loader-spinner";
import { Auth } from "aws-amplify";
import SetAdminPassword from "./views/pages/SetAdminPassword";
import { tokenRefresh } from "./Services/authService";
import { decrypt, getClientID } from "./Services/common";
import configureAmplify from "./Services/amplifyConfig";
import { hexToRgba } from "./utils";
import Cookies from "js-cookie";
import PatientDashboardRedirect from "./views/pages/PatientDashboardRedirect";
import {
  checkTokenValidity,
  getProviderRoles,
  logoutAndRedirect,
  postSessionInfo,
} from "./utils/common";
import IdleTimerComponent from "./components/IdleTimerComponent";
import FileUploadFrmQR from "./views/pages/FileUploadFrmQR";
import { v4 as uuidv4 } from "uuid";
import AuthListener from "./Services/AuthListener";
import SSO from "./views/pages/SSO";
// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login"));
const UpdatePassword = React.lazy(() => import("./views/pages/updatePassword"));
const Signup = React.lazy(() => import("./views/pages/signup"));
const SetPassword = React.lazy(() => import("./views/pages/SetPassword"));

const PatientRegistration = React.lazy(() =>
  import("./views/pages/PatientRegistration")
);

const PrivateRoute = ({ component: Components, ...rest }) => {
  return localStorage.getItem("accessToken") ? (
    <Route {...rest} render={(props) => <Components {...props} />} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
      }}
    />
  );
};
const CN_AUTOLOG_INIT = Number(process.env.REACT_APP_CN_AUTOLOG_INIT);
const IDEAL_TIMEOUT_INIT = Number(process.env.REACT_APP_IDEAL_TIMEOUT_INIT);
const IDEAL_TIMEOUT = Number(process.env.REACT_APP_IDEAL_TIMEOUT);
const CIT_INTERVAL = Number(process.env.REACT_APP_IDEAL_CIT_INTERVAL);
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      isLatestTab: true,
      timerOut: IDEAL_TIMEOUT_INIT,
      uuid: uuidv4(),
    };
    this.getProfile = this.getProfile.bind(this);
    this.redirectPage = this.redirectPage.bind(this);
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.checkDuplicateTab = this.checkDuplicateTab.bind(this);
    this.setTabObj = this.setTabObj.bind(this);

    this.idleTimerRef = createRef(null);
    this.idealTimerCheck = null;
    this.auth_log_enable = process.env.REACT_APP_ENBLE_AUTH_LOG === "true";
  }

  async componentDidMount() {
    let accessToken = localStorage.getItem("accessToken");
    const storedSkin = Cookies.get("skin");
    if (storedSkin !== undefined && accessToken !== undefined) {
      const colorPrimary = storedSkin;
      const colorPrimary_light = hexToRgba(colorPrimary, 0.1);
      document.documentElement.style.setProperty(
        "--primaryColor",
        colorPrimary
      );
      document.documentElement.style.setProperty(
        "--primaryColor-light",
        colorPrimary_light
      );
    }
    if (this.props.timerOut !== null) {
      this.setState({ timerOut: this.props.timerOut });
    }
    let end = parseInt(localStorage.getItem("timer"));
    let start = new Date().getTime();

    const diff = start - end;
    if (diff > IDEAL_TIMEOUT) {
      logoutAndRedirect(
        this.props.profile,
        this.props?.dispatch,
        this.props?.tabUid,
        this.props?.sessionDuration
      )
        .then(() => {
          window.location.href = "/#/login";
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    }

    if (
      this.props.profile?.email !== undefined &&
      localStorage.getItem("email") !== null
    ) {
      const clientId = await getClientID(
        this.props.profile.email || localStorage.getItem("email")
      );
      configureAmplify(
        decrypt(
          clientId,
          this.props.profile.email || localStorage.getItem("email")
        ),
        "USER_PASSWORD_AUTH"
      );

      this.getProfile();

      if (this.props.profile?.profile) {
        const profilesArray = this.props.profile.profile
          .split(",")
          .map((profile) => profile.trim());
        if (profilesArray.includes("CARE_NAVIGATOR")) {
          //CARE_NAVIGATORS
          this.props.dispatch(ActionCreators.startCNInterval(CN_AUTOLOG_INIT));
        }
      }
    } else {
      configureAmplify("default-user-pool-web-client-id", "USER_PASSWORD_AUTH");
    }
    if (
      window.performance &&
      typeof window.performance.getEntriesByType === "function" &&
      window.performance.getEntriesByType("navigation")?.length > 0 &&
      window.performance.getEntriesByType("navigation")[0]?.type ===
        "back_forward"
    ) {
      let sessionGuid = this.createUID();
      sessionStorage.setItem("tab-uid", sessionGuid);
    }

    // clear locastorage on reload or close tab.
    window.onbeforeunload = (event) => {
      // if (this.checkDuplicateTab()) {
      //   localStorage.removeItem("browser-tab");
      // }
      //localStorage.clear();
    };

    let sessionGuid = sessionStorage.getItem("tab-uid") || this.createUID();
    sessionStorage.setItem("tab-uid", sessionGuid);
    this.props.dispatch(ActionCreators.setTabUid(sessionGuid));
    this.checkDuplicateTab();

    checkTokenValidity(this.props.dispatch, sessionGuid); // to clear localstorage if token invalid
  }

  checkIdealTime = async () => {
    const lastIdleTimeStamp = this.idleTimerRef.current?.getLastIdleTime();
    const currentTimeStamp = Date.now();
    const idealTime = currentTimeStamp - lastIdleTimeStamp;
    //const idealTime = lastIdleTimeStamp ? currentTimeStamp - lastIdleTimeStamp : IDEAL_TIMEOUT + 1;
    //console.log(idealTime / 60000);
    if (lastIdleTimeStamp) {
      if (idealTime >= IDEAL_TIMEOUT) {
        logoutAndRedirect(
          this.props.profile,
          this.props?.dispatch,
          this.props?.tabUid,
          this.props?.sessionDuration,
          true //hadIdealTime
        )
          .then(() => {
            window.location.href = "/";
          })
          .catch((error) => {
            console.log("Logout failed:", error);
          });
      } else {
        const splitToken = localStorage.getItem("accessToken")?.split(".");
        const decodeToken = atob(splitToken[1]);
        const tokenObj = JSON.parse(decodeToken);
        const currentTime = Math.floor(Date.now() / 1000);
        if (tokenObj.exp - currentTime <= 10 * 60) {
          await tokenRefresh();
        }
      }
    } else {
      clearInterval(this.idealTimerCheck);
    }
  };
  handleOnIdle(event) {
    if (localStorage.getItem("accessToken")) {
      this.props.dispatch(ActionCreators.stopCNInterval());
      if (this.idealTimerCheck === null) {
        this.idealTimerCheck = setInterval(this.checkIdealTime, CIT_INTERVAL);
      }
    }
  }

  async getProfile() {
    if (
      localStorage.getItem("accessToken") === "" ||
      localStorage.getItem("accessToken") === undefined ||
      localStorage.getItem("accessToken") === null
    ) {
      console.log("User is not logged in!");
    } else {
      if (this.props.currentParentClinicID === null) {
        console.log("Parent Clinic and Clinic are not selected!");
        logoutAndRedirect(
          this.props.profile,
          this.props?.dispatch,
          this.props?.tabUid,
          this.props?.sessionDuration
        )
          .then(() => {
            window.location.href = "/#/login";
          })
          .catch((error) => {
            console.error("Logout failed:", error);
          });
      } else {
        await Auth.currentAuthenticatedUser()
          .then((user) => {
            return Auth.currentSession();
          })
          .then((session) => {
            const accessToken = session.accessToken.jwtToken;
            const idToken = session.idToken.jwtToken;
            localStorage.setItem("idToken", idToken);
            localStorage.setItem("accessToken", accessToken);
            return Auth.currentUserInfo();
          })
          .then((userInfo) => {
            let userobject = userInfo.attributes;

            const userProfiles = [...userobject.profile.split(",")].map(
              (profile) => profile.trim()
            );
            if (
              (userProfiles?.length === 1 &&
                userProfiles?.includes("CARE_NAVIGATOR")) ||
              (userProfiles?.length === 2 &&
                userProfiles?.includes("CARE_NAVIGATOR") &&
                userProfiles?.includes("REPORT"))
            ) {
              this.props.dispatch(ActionCreators.setIsCNavigator(true));
              this.getCareNavigatorByEmail(userobject);
            } else {
              this.getTherapistByEmail(userobject);
            }
          })
          .catch((error) => {
            console.log("Error:", error);
            // let localStoragedata = localStorage.getItem("browser-tab");
            // localStorage.clear();
            // localStorage.setItem("browser-tab", localStoragedata);
            // // Redirect to the login page
            // window.location.href = "/#/login";
          });
      }
    }
  }
  setTherapistClinics = (clinicData) => {
    this.props.dispatch(ActionCreators.addClinicBranchs(clinicData));

    if (clinicData.length > 0) {
      if (this.props.currentClinicID === "null") {
        this.props.dispatch(
          ActionCreators.setClinicBranch(clinicData[0].clinicId)
        );
        this.props.dispatch(
          ActionCreators.setParentClinic(clinicData[0].parentClinicId)
        );
        this.props.dispatch(ActionCreators.setClinicTZ(clinicData[0].timezone));
        localStorage.setItem("UserTimeZone", clinicData[0].timezone);
        localStorage.setItem("UserClinic", clinicData[0].clinicId);
        localStorage.setItem("UserParentClinic", clinicData[0].parentClinicId);
        getProviderRoles(
          clinicData[0].parentClinicId,
          clinicData[0].clinicId,
          this.props.profile,
          this.props.dispatch,
          this.props.isCareNavigator
        )
          .then(() => {
            // console.log("in app");
          })
          .catch((error) => {
            this.props.dispatch(ActionCreators.setUserRoles([]));
            logoutAndRedirect(
              this.props.profile,
              this.props?.dispatch,
              this.props?.tabUid,
              this.props?.sessionDuration
            )
              .then(() => {
                var pageType = {
                  pathname: "/login",
                };
                this.props.history.push(pageType);
              })
              .catch((error) => {
                console.error("Logout failed:", error);
              });
          });
      }
    }
  };

  getCareNavigatorByEmail = (search) => {
    var data = {};
    var headerdata = "";
    _callApi(
      data,
      "REACT_APP_CLINIC_CLAIMS",
      headerdata,
      2,
      "care-navigator?email=" + encodeURIComponent(search.email)
    ).then((response) => {
      if (response.status === 202) {
        let myPatientsData = response.data;
        if (myPatientsData.length > 0) {
          var userobject = search;
          userobject["id"] = myPatientsData[0].id;
          userobject["careNavigatorId"] = myPatientsData[0].careNavigatorId;
          userobject["isAdmin"] =
            myPatientsData[0].enumerationType === "NPI-2" ? true : false;
          localStorage.getItem({ id: myPatientsData[0].id });
          this.props.dispatch(ActionCreators.addProfile(userobject));

          if (myPatientsData[0].clinics?.length > 0) {
            this.setTherapistClinics(myPatientsData[0].clinics);
          }

          this.props.dispatch(
            ActionCreators.setParentClinic(
              myPatientsData[0].clinics?.[0].parentClinicId
            )
          );

          localStorage.setItem(
            "UserParentClinic",
            myPatientsData[0].clinics?.[0].parentClinicId
          );
          this.props.dispatch(
            ActionCreators.setClinicTZ(myPatientsData[0].clinics?.[0].timezone)
          );
          localStorage.setItem(
            "UserTimeZone",
            myPatientsData[0].clinics?.[0].timezone
          );
        } else {
          //logout
        }
      } else {
        //logout
      }
    });
  };

  getTherapistByEmail(search) {
    var data = {};
    var headerdata = "";
    if (search.email !== undefined) {
      this.props.dispatch({ type: "SET_LOADER" });
      _callApi(
        data,
        "REACT_APP_GET_THERAPIST_URL",
        headerdata,
        2,
        "?email=" + encodeURIComponent(search.email)
      ).then((response) => {
        if (response?.status === 202 && response?.data?.length) {
          var myPatientsData = response.data;
          var userobject = search;
          // userobject["clinicNpiId"] = myPatientsData[0].clinicNpiId;
          // userobject["clinicTimeZone"] = myPatientsData[0].timezone;
          userobject["id"] = myPatientsData[0].id;
          userobject["isAdmin"] =
            myPatientsData[0].enumerationType === "NPI-2" ? true : false;
          this.props.dispatch(ActionCreators.addProfile(userobject));
          //localStorage.setItem("UserTimeZone", myPatientsData[0].timezone);

          const userProfiles = [...userobject.profile.split(",")].map(
            (profile) => profile.trim()
          );
          if (
            ((userProfiles.includes("SYSTEM ADMINISTRATOR") ||
              userProfiles.includes("SYSTEM_ADMINISTRATOR")) &&
              ((userProfiles.includes("REPORT") && userProfiles.length === 2) ||
                (!userProfiles.includes("REPORT") &&
                  userProfiles.length === 1))) ||
            ((userProfiles.includes("SYSTEM ADMINISTRATOR") ||
              userProfiles.includes("SYSTEM_ADMINISTRATOR")) &&
              userProfiles.includes("LOCAL_ADMIN") &&
              ((userProfiles.includes("REPORT") && userProfiles.length === 3) ||
                (!userProfiles.includes("REPORT") &&
                  userProfiles.length === 2))) ||
            (userProfiles.includes("LOCAL_ADMIN") &&
              ((userProfiles.includes("REPORT") && userProfiles.length === 2) ||
                (!userProfiles.includes("REPORT") &&
                  userProfiles.length === 1)))
          ) {
            this.props.dispatch(
              ActionCreators.setParentClinic(myPatientsData[0].parentClinicId)
            );

            localStorage.setItem(
              "UserParentClinic",
              myPatientsData[0].parentClinicId
            );
            if (
              [...userobject.profile.split(",")]
                .map((profile) => profile.trim())
                .includes("REPORT")
            ) {
              this.props.dispatch(
                ActionCreators.setUserRoles(["report", "sysadmin"])
              );
            } else {
              this.props.dispatch(ActionCreators.setUserRoles(["sysadmin"]));
            }
            this.props.dispatch(
              ActionCreators.setConfig("", myPatientsData[0].parentClinicId)
            );

            //for local admin
            if (myPatientsData[0].therapistClinics?.length > 0) {
              this.props.dispatch(
                ActionCreators.addClinicBranchs(
                  myPatientsData[0].therapistClinics
                )
              );
              // this.props.dispatch(
              //   ActionCreators.setClinicBranch(
              //     myPatientsData[0].therapistClinics?.[0].clinicId
              //   )
              // );
              this.props.dispatch(
                ActionCreators.setClinicTZ(
                  myPatientsData[0].therapistClinics?.[0].timezone
                )
              );
              localStorage.setItem(
                "UserTimeZone",
                myPatientsData[0].therapistClinics?.[0].timezone
              );
              // localStorage.setItem(
              //   "UserClinic",
              //   myPatientsData[0].therapistClinics?.[0].clinicId
              // );
            }
          } else {
            if (myPatientsData[0].therapistClinics.length > 0) {
              // this.props.dispatch(
              //   ActionCreators.addClinicBranchs(
              //     myPatientsData[0].therapistClinics
              //   )
              // );
              // let branch = this.props.currentClinicID;
              // if (this.props.currentClinicID === "null") {
              //   branch = myPatientsData[0].therapistClinics[0].clinicId;
              // }
              // //this.getMyPatients(userobject);
              // this.getProviderRoles(this.props.currentParentClinicID, branch);

              this.setTherapistClinics(myPatientsData[0].therapistClinics);
            } else {
              if (
                ["SYSTEM ADMINISTRATOR", "SYSTEM_ADMINISTRATOR"].some((a) =>
                  [...userobject.profile.split(",")]
                    .map((profile) => profile.trim())
                    .includes(a)
                ) &&
                myPatientsData[0].parentClinicId !== null
              ) {
                this.props.dispatch(
                  ActionCreators.setParentClinic(
                    myPatientsData[0].parentClinicId
                  )
                );

                localStorage.setItem(
                  "UserParentClinic",
                  myPatientsData[0].parentClinicId
                );

                if (
                  [...userobject.profile.split(",")]
                    .map((profile) => profile.trim())
                    .includes("REPORT")
                ) {
                  this.props.dispatch(
                    ActionCreators.setUserRoles(["report", "sysadmin"])
                  );
                } else {
                  this.props.dispatch(
                    ActionCreators.setUserRoles(["sysadmin"])
                  );
                }

                this.props.dispatch(
                  ActionCreators.setConfig("", myPatientsData[0].parentClinicId)
                );
              } else {
                //logout
              }
            }
          }

          //this.setState({ loader: true });
        }
      });
    }
  }

  getMyPatients(values) {
    this.props.dispatch({ type: "SET_LOADER" });
    var data = {};
    var headerdata = "";
    _callApi(
      data,
      "REACT_APP_PATIENT_LIST_URL",
      headerdata,
      2,
      "?therapistId=" + values.id + "&clinicsNpiId=" + values.clinicNpiId
    ).then((response) => {
      if (response) {
        if (response.status === 202) {
          var myPatientsData = response.data.patients;
          this.props.dispatch(ActionCreators.addPatient(myPatientsData));
          this.setState({ loader: false });
        } else {
          this.setState({ loader: false });
        }
      }
    });
  }

  redirectPage() {
    const self = this;
    var pageType = {
      pathname: "/login",
    };

    self.props.history.push(pageType);
  }

  async handleOnAction(event) {
    let accessToken = localStorage.getItem("accessToken");
    localStorage.setItem("timer", new Date().getTime());
    if (
      accessToken === "" ||
      accessToken === undefined ||
      accessToken === null
    ) {
    } else {
      const splitToken = accessToken?.split(".");
      const decodeToken = atob(splitToken[1]);
      const tokenObj = JSON.parse(decodeToken);
      const currentTime = Math.floor(Date.now() / 1000);
      if (tokenObj.exp - currentTime < 10 * 60) {
        return tokenRefresh()
          .then(() => {
            //console.log("Token refreshed");
          })
          .catch((error) => {
            console.log("Error:", error);
            logoutAndRedirect(
              this.props.profile,
              this.props?.dispatch,
              this.props?.tabUid,
              this.props?.sessionDuration
            )
              .then(() => {
                window.location.href = "/";
              })
              .catch((error) => {
                console.error("Logout failed:", error);
              });
          });
      }
    }
  }

  handleOnActive(event) {
    //console.log("onactive");
    clearInterval(this.idealTimerCheck);
    this.idleTimerRef.current.reset();
    this.idealTimerCheck = null;
    // this.getProfile();
    if (this.props.profile?.profile) {
      const profilesArray = this.props.profile.profile
        .split(",")
        .map((profile) => profile.trim());
      if (profilesArray.includes("CARE_NAVIGATOR")) {
        //CARE_NAVIGATORS
        postSessionInfo(
          0,
          this.props.currentParentClinicID,
          this.props.profile?.id,
          false,
          this.props.sessionDuration?.uuid,
          false
        );
        this.props.dispatch(ActionCreators.startCNInterval(CN_AUTOLOG_INIT));
      }
    }
  }

  createUID() {
    let guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    return guid;
  }

  setTabObj(isFirstTime) {
    // let sessionGuid = sessionStorage.getItem("tab-uid") || this.createUID();
    let sessionGuid = this.props.tabUid || this.createUID();
    let newTabObj = {
      guid: sessionGuid,
      timestamp: new Date().getTime(),
    };
    let latesttabObj = JSON.parse(localStorage.getItem("browser-tab")) || null;

    if ((isFirstTime && !latesttabObj) || sessionGuid === latesttabObj?.guid) {
      localStorage.setItem("browser-tab", JSON.stringify(newTabObj));
      this.setState({ isLatestTab: true, loader: false });
    } else if (
      isFirstTime &&
      this.state.isLatestTab &&
      latesttabObj &&
      sessionGuid !== latesttabObj?.guid
    ) {
      localStorage.setItem("browser-tab", JSON.stringify(newTabObj));
      this.setState({ isLatestTab: true, loader: false });
    } else {
      this.setState({ isLatestTab: false, loader: false });
    }
  }

  async checkDuplicateTab() {
    // let sessionGuid = sessionStorage.getItem("tab-uid") || this.createUID();
    let sessionGuid = this.props.tabUid;
    let tabObj = JSON.parse(localStorage.getItem("browser-tab")) || null;
    let isFirstTime = true;
    // sessionStorage.setItem("tab-uid", sessionGuid);
    // this.props.dispatch(ActionCreators.setTabUid(sessionGuid));
    if (
      (isFirstTime && tabObj === null) ||
      tabObj?.timestamp < new Date().getTime() - 15000 ||
      tabObj?.guid === sessionGuid
    ) {
      this.setTabObj(isFirstTime);
      setInterval(() => this.setTabObj(isFirstTime), 1000);
      isFirstTime = false;
      return true;
    } else {
      if (isFirstTime && tabObj?.guid !== sessionGuid) {
        this.setTabObj(isFirstTime);
        setInterval(() => this.setTabObj(isFirstTime), 1000);
        isFirstTime = false;
        return true;
      }
      isFirstTime = false;
      this.setState({ loader: false, isLatestTab: false });
      return false;
    }
  }

  shouldComponentUpdate(props, state) {
    if (!this.state.isLatestTab) {
      clearInterval(this.props.cnIntervalId); // to clear for cn timer when access denied
    }
    return (
      state.isLatestTab !== this.state.isLatestTab ||
      state.loader !== this.state.loader
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tabUid !== this.props.tabUid) {
      this.checkDuplicateTab();
    }
  }
  componentWillUnmount() {
    clearInterval(this.idealTimerCheck); // Clear the interval
    this.props.dispatch(ActionCreators.stopCNInterval());
  }

  render() {
    if (this.state.loader) {
      return (
        <div className="test Site-content--oval">
          <Loader
            type="Oval"
            color="var(--primaryColor)"
            height={40}
            width={40}
            className="graph-laoder"
          />
        </div>
      );
    }
    if (this.state.isLatestTab) {
      return (
        <>
          <IdleTimerComponent
            idleTimerRef={this.idleTimerRef}
            handleOnAction={this.handleOnAction}
            handleOnIdle={this.handleOnIdle}
            handleOnActive={this.handleOnActive}
          />
          <BrowserRouter basename="/#">
            {this.auth_log_enable && <AuthListener />}
            <React.Suspense
              fallback={
                <Loader
                  type="ThreeDots"
                  color="var(--primaryColor)"
                  height={40}
                  width={40}
                  className="graph-laoder"
                />
              }
            >
              <Switch>
                <Route
                  exact
                  path="/"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                {/* <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                /> */}
                <Route
                  exact
                  path="/verification"
                  name="Register Page"
                  render={(props) => <Signup {...props} />}
                />
                <Route
                  exact
                  path="/update-password"
                  name="Update password Page"
                  render={(props) => <UpdatePassword {...props} />}
                />
                <Route
                  exact
                  path="/set-password"
                  name="Set Password"
                  render={(props) => <SetPassword {...props} />}
                />
                <Route
                  exact
                  path="/redirect/set-password"
                  name="Set Admin Password"
                  render={(props) => <SetAdminPassword {...props} />}
                />
                <Route
                  exact
                  path="/SSO"
                  name="SSO"
                  render={(props) => <SSO {...props} />}
                />
                <Route
                  exact
                  path="/customexerciseupload/:id"
                  name="file upload QR"
                  render={(props) => <FileUploadFrmQR {...props} />}
                />
                <Route
                  exact
                  path="/open"
                  name="Patient Registration"
                  render={(props) => <PatientRegistration {...props} />}
                />
                <Route
                  exact
                  path="/health"
                  name="health"
                  render={() => <h6>Hey There!!! The App is Healthy</h6>}
                />
                <Route
                  exact
                  path="/redirect/patient-dashboard/:id/:patient_id/:excercise_id/:episodeId/:branch_id/:therapist_id"
                  name="Patient Dashboard Redirect"
                  render={(props) => <PatientDashboardRedirect {...props} />}
                />
                <PrivateRoute path="/" name="Home" component={TheLayout} />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </>
      );
    } else {
      return (
        <div className="access-denied p-3">
          <strong>Access Denied</strong>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.user.profile,
    currentClinicTZ: state.user.currentClinicTZ,
    currentParentClinicID: state.user.currentParentClinicID,
    currentClinicID: state.user.currentClinicID,
    userRoles: state.user.userRoles,
    patient: state.user.patient,
    config: state.user.config,
    tabUid: state.user.tabUid,
    sessionDuration: state.user.sessionDuration,
    timerOut: state.user.timerOut,
    cnIntervalId: state.user.cnIntervalId,
    isCareNavigator: state.user.isCareNavigator,
  };
};

export default connect(mapStateToProps)(App);
