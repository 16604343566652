/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import AWS from "aws-sdk";
import { _callApi } from "src/Services/baseService";
import { ActionCreators } from "src/actions/profile";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProviderRoles, postSessionInfo } from "src/utils/common";
import { getDashboard } from "src/utils";
import Moment from "../../common/UserMoment";
import { v4 as uuidv4 } from "uuid";

const IDEAL_TIMEOUT_INIT = Number(process.env.REACT_APP_IDEAL_TIMEOUT_INIT);
const ssoClientId = process.env.REACT_APP_SSO_CLIENTID;
const ssoClientSecret = process.env.REACT_APP_SSO_CLIENTSECRET;
const ssoUserpoolId = process.env.REACT_APP_USERPOOLID;
const ssoRegion = process.env.REACT_APP_REGION;

AWS.config.update({
  userPoolId: ssoUserpoolId,
  userPoolWebClientId: ssoClientId,
  authenticationFlowType: "USER_PASSWORD_AUTH",
  region: ssoRegion,
});
AWS.config.update({ region: ssoRegion });
const SSO = () => {
  let uuid = uuidv4();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const userRoles = useSelector((state) => state.user.userRoles);
  useEffect(() => {
    let url = location.search.split("&");
    let reftoken = url[0]?.split("token=")?.[1];
    let username = url[1]?.split("sub=")?.[1];
    refreshTokens(reftoken, username);
  }, []);

  const computeSecretHash = (username, cId) => {
    const crypto = require("crypto");
    const hash = crypto
      .createHmac("sha256", ssoClientSecret)
      .update(username + cId)
      .digest("base64");
    return hash;
  };
  const refreshTokens = async (refreshToken, username) => {
    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider();

    const params = {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: ssoClientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
        SECRET_HASH: computeSecretHash(username, ssoClientId),
      },
    };

    try {
      const data = await cognitoIdentityServiceProvider
        .initiateAuth(params)
        .promise();
      //   console.log("User authenticated with refresh token:", data);
      var accesstoken = data.AuthenticationResult.AccessToken;
      var idToken = data.AuthenticationResult.IdToken;
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("accessToken", accesstoken);
      localStorage.setItem("UserTimeZone", null);
      localStorage.setItem("UserClinic", null);
      localStorage.setItem("UserParentClinic", null);
      getUserAttributes(data.AuthenticationResult.AccessToken);
    } catch (err) {
      console.error("Error refreshing tokens:", err);
      window.location.href = "/";
    }
  };
  const getUserAttributes = async (accessToken) => {
    const params = {
      AccessToken: accessToken,
    };

    const cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider();

    try {
      const userData = await cognitoIdentityServiceProvider
        .getUser(params)
        .promise();
      const attributesObject = userData.UserAttributes.reduce(
        (acc, { Name, Value }) => {
          acc[Name] = Value;
          return acc;
        },
        {}
      );

      //   console.log("User attributes:", attributesObject);
      getTherapistByEmail(attributesObject);
      return attributesObject;
    } catch (error) {
      console.error("Error fetching user attributes:", error);
      return null;
    }
  };
  const setTherapistClinics = (clinicData, profile) => {
    dispatch(ActionCreators.addClinicBranchs(clinicData));
    if (clinicData.length > 0) {
      dispatch(ActionCreators.setClinicBranch(clinicData[0].clinicId));
      dispatch(ActionCreators.setParentClinic(clinicData[0].parentClinicId));
      dispatch(ActionCreators.setClinicTZ(clinicData[0].timezone));
      localStorage.setItem("UserTimeZone", clinicData[0].timezone);
      localStorage.setItem("UserClinic", clinicData[0].clinicId);
      localStorage.setItem("UserParentClinic", clinicData[0].parentClinicId);
      getProviderRoles(
        clinicData[0].parentClinicId,
        clinicData[0].clinicId,
        profile,
        dispatch
      )
        .then(() => {
          dispatch(
            ActionCreators.setLoginTime(
              Moment(),
              clinicData[0].parentClinicId,
              uuid
            )
          );
          if (
            [...profile.profile.split(",")]
              .map((profile) => profile.trim())
              .includes("CARE_NAVIGATORS")
          ) {
            postSessionInfo(
              0,
              clinicData[0].parentClinicId,
              profile?.id,
              true,
              uuid
            );
          }
          if (
            [...profile.profile.split(",")]
              .map((profile) => profile.trim())
              .includes("CARE_NAVIGATORS")
          ) {
            dispatch(ActionCreators.setTimerOut(IDEAL_TIMEOUT_INIT));
          }

          var pageType;
          pageType = {
            pathname: getDashboard(userRoles),
          };
          history.push(pageType);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getTherapistByEmail = (search) => {
    var data = {};
    var headerdata = "";
    _callApi(
      data,
      "REACT_APP_GET_THERAPIST_URL",
      headerdata,
      2,
      "?email=" + encodeURIComponent(search.email)
    ).then((response) => {
      if (response) {
        if (response.status === 202) {
          var myPatientsData = response.data;

          if (myPatientsData.length > 0) {
            var userobject = search;
            userobject["id"] = myPatientsData[0].id;
            userobject["isAdmin"] =
              myPatientsData[0].enumerationType === "NPI-2" ? true : false;
            localStorage.getItem({ id: myPatientsData[0].id });
            dispatch(ActionCreators.addProfile(userobject));
            const userProfiles = [...userobject.profile.split(",")].map(
              (profile) => profile.trim()
            );

            if (
              ((userProfiles.includes("SYSTEM ADMINISTRATOR") ||
                userProfiles.includes("SYSTEM_ADMINISTRATOR")) &&
                ((userProfiles.includes("REPORT") &&
                  userProfiles.length === 2) ||
                  (!userProfiles.includes("REPORT") &&
                    userProfiles.length === 1))) ||
              ((userProfiles.includes("SYSTEM ADMINISTRATOR") ||
                userProfiles.includes("SYSTEM_ADMINISTRATOR")) &&
                userProfiles.includes("LOCAL_ADMIN") &&
                ((userProfiles.includes("REPORT") &&
                  userProfiles.length === 3) ||
                  (!userProfiles.includes("REPORT") &&
                    userProfiles.length === 2))) ||
              (userProfiles.includes("LOCAL_ADMIN") &&
                ((userProfiles.includes("REPORT") &&
                  userProfiles.length === 2) ||
                  (!userProfiles.includes("REPORT") &&
                    userProfiles.length === 1)))
            ) {
              dispatch(
                ActionCreators.setParentClinic(myPatientsData[0].parentClinicId)
              );

              localStorage.setItem(
                "UserParentClinic",
                myPatientsData[0].parentClinicId
              );

              if (userProfiles.includes("REPORT")) {
                dispatch(ActionCreators.setUserRoles(["report", "sysadmin"]));
              } else {
                dispatch(ActionCreators.setUserRoles(["sysadmin"]));
              }
              dispatch(
                ActionCreators.setConfig("", myPatientsData[0].parentClinicId)
              );

              //for local admin
              if (myPatientsData[0].therapistClinics?.length > 0) {
                dispatch(
                  ActionCreators.addClinicBranchs(
                    myPatientsData[0].therapistClinics
                  )
                );

                dispatch(
                  ActionCreators.setClinicTZ(
                    myPatientsData[0].therapistClinics?.[0].timezone
                  )
                );
                localStorage.setItem(
                  "UserTimeZone",
                  myPatientsData[0].therapistClinics?.[0].timezone
                );
              }

              var pageType = {
                pathname: "/admin-dashboard",
              };
              history.push(pageType);
            } else {
              if (myPatientsData[0].therapistClinics.length > 0) {
                setTherapistClinics(
                  myPatientsData[0].therapistClinics,
                  userobject
                );
              } else {
                if (
                  ["SYSTEM ADMINISTRATOR", "SYSTEM_ADMINISTRATOR"].some((a) =>
                    userProfiles.includes(a)
                  ) &&
                  myPatientsData[0].parentClinicId !== null
                ) {
                  dispatch(
                    ActionCreators.setParentClinic(
                      myPatientsData[0].parentClinicId
                    )
                  );

                  localStorage.setItem(
                    "UserParentClinic",
                    myPatientsData[0].parentClinicId
                  );

                  if (userProfiles.includes("REPORT")) {
                    dispatch(
                      ActionCreators.setUserRoles(["report", "sysadmin"])
                    );
                  } else {
                    dispatch(ActionCreators.setUserRoles(["sysadmin"]));
                  }

                  dispatch(
                    ActionCreators.setConfig(
                      "",
                      myPatientsData[0].parentClinicId
                    )
                  );
                  let pageType = {
                    pathname: "/admin-dashboard",
                  };
                  history.push(pageType);
                }
              }
            }
          }
        } else {
          let data = localStorage.getItem("browser-tab");
          localStorage.clear();
          localStorage.setItem("browser-tab", data);
        }
      }
    });
  };
  return (
    <div>
      <Loader
        type="ThreeDots"
        color="var(--primaryColor)"
        height={40}
        width={40}
        className="graph-laoder"
      />
    </div>
  );
};

export default SSO;
