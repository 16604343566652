/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Alert } from "@mui/material";
import Footer from "src/components/Footer";
import Header from "src/components/Header";
import * as messageClass from "../../common/message";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { decrypt } from "src/Services/common";
import { _callApi } from "src/Services/baseService";
import Loader from "react-loader-spinner";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import _debounce from "lodash/debounce";
import LoadingOverlay from "src/components/Modal/LoadingOverlay";
import APIMessage from "src/components/APIMessage";
import { applySkinColor } from "src/utils";
import moment from "../../common/UserMoment";
const FileUploadFrmQR = (props) => {
  const s3TempBucket = process.env.REACT_APP_S3_TEMP_BUCKET;

  const location = useLocation();
  const hiddenFileInput = useRef(null);

  const [, setPClinicId] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [tempUpldFileName, setTempUpldFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileToUploadcheck, setFileToUploadCheck] = useState(false);
  const [tempUploading, setTempUploading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [msg] = useState("");
  const [sucessMsg, setSucessMsg] = useState("");
  const [tempMovData, setTempMovData] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [accessKeyId, setAccessKeyId] = useState(null);
  const [secretAccessKey, setSecretAccessKey] = useState(null);
  const [s3Client, setS3Client] = useState(null);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [username, setUsername] = useState("");
  const [tempMovementId, setTempMovementId] = useState(null);
  const [config, setConfig] = useState(null);
  const [configure, setConfigure] = useState(null);

  const [fileType, setFileType] = useState("image");
  const [s3tempFilePath, setS3tempFilePath] = useState(null);

  const handleUploadBtnClicked = () => {
    if (!fileToUploadcheck) {
      hiddenFileInput.current.click();
    }
  };
  const uploadToTemp = async () => {
    setTempUploading(true);
    let uniqueTempName =
      tempMovData?.therapistId +
      "/" +
      tempMovData?.therapistId +
      "_" +
      moment().valueOf() +
      "." +
      fileExtension;

    setS3tempFilePath(uniqueTempName);
    const params = {
      Bucket: s3TempBucket,
      Key: uniqueTempName,
      Body: fileToUpload,
      Metadata: {
        custom_movement: tempMovData?.therapistId,
        cloud: "True",
        custom_video_process_status: "No",
        json: JSON.stringify({
          email: tempMovData?.email,
          accessToken: tempMovData?.token,
          videofilename: uniqueTempName,
        }),
      },
    };
    const command = new PutObjectCommand(params);
    try {
      await s3Client.send(command);
      console.log("uploading....");
      hiddenFileInput.current.value = "";
    } catch (err) {
      setFileError(err);
      console.log("Error", err);
    } finally {
      setTempUploading(false);
    }
  };
  const handleFileChange = async (e) => {
    const fileUploaded = e.target.files[0];
    const uploadedFileTempName = fileUploaded?.name;
    let ext = fileUploaded?.name.split(".").pop().toLowerCase();
    setFileExtension(ext);
    setTempUpldFileName(uploadedFileTempName);
    const fType = fileUploaded.type;
    if (fType.includes("image")) {
      setFileType("image");
    } else if (fType.includes("video")) {
      setFileType("video");
    }

    if (!["png", "jpg", "jpeg", "mp4", "mov"].includes(ext)) {
      setFileError(messageClass.FILE_FORMAT_REQUIRED);
    } else {
      var sizeInMB = parseFloat(fileUploaded.size / (1024 * 1024)).toFixed(2);
      if (sizeInMB > 500) {
        setFileError(messageClass.FILE_SIZE_REQUIRED);
      } else {
        setFileToUpload(fileUploaded);
        setFileToUploadCheck(true);
        setFileError("");
        hiddenFileInput.current.value = "";
      }
    }
  };
  const getTempMovementDetails = (tempMovementId) => {
    var headerdata = "";
    _callApi(
      "",
      "REACT_APP_CLINIC_ROLES",
      headerdata,
      2,
      `tempmovement/${tempMovementId}`
    ).then((response) => {
      if (response.status === 200) {
        setTempMovData(response?.data);
        setLoadingPage(false);
      } else {
        setLoadingPage(false);
        setTokenExpired(true);
      }
    });
  };
  const deleteTempMovementDetails = () => {
    var headerdata = "7";
    _callApi(
      "",
      "REACT_APP_CLINIC_ROLES",
      headerdata,
      4,
      `/temp_movement/${tempMovementId}`,
      tempMovData?.token
    ).then((response) => {
      setUsername("");
      setTokenExpired(true);
    });
  };
  const patchTempMovementDetails = (UPLOADED) => {
    var headerdata = "7";
    _callApi(
      {
        status: UPLOADED,
        tempFileName: s3tempFilePath,
        uploadedFileName: tempUpldFileName,
        fileType: fileType,
      },
      "REACT_APP_CLINIC_ROLES",
      headerdata,
      5,
      `/temp_movement/${tempMovementId}`,
      tempMovData?.token
    ).then((response) => {
      if (response.status === 200) {
        setSucessMsg("Exercise uploaded successfully");
        setSaveLoading(false);
      }
    });
  };
  const delayedUploadFile = _debounce(() => {
    uploadToTemp();
  }, 1000);
  const checkMovementAvail = () => {
    if (fileToUploadcheck) {
      var headerdata = "";
      _callApi(
        "",
        "REACT_APP_CLINIC_ROLES",
        headerdata,
        2,
        `tempmovement/${tempMovementId}`
      ).then((response) => {
        if (response.status === 200) {
          // handleSave();
          patchTempMovementDetails("UPLOADED");
        } else {
          setTokenExpired(true);
        }
      });
    } else {
      setFileError(messageClass.FILE_REQUIRED);
    }
  };
  const getKeysFromEmail = (email, token) => {
    let emailId = encodeURIComponent(email?.toLowerCase());
    _callApi(
      {},
      "REACT_APP_kEY_FETCH",
      "7",
      2,
      `?app=web&email=${emailId}`,
      token
    ).then((response) => {
      if (response.status === 200) {
        setAccessKeyId(decrypt(response.data.tokenTwo, email));
        setSecretAccessKey(decrypt(response.data.tokenOne, email));
      }
    });
  };

  const getConfigDetails = (parentClinicId, token) => {
    _callApi(
      {},
      "REACT_APP_CLINIC_ROLES",
      "7",
      2,
      `parent/clinic/${parentClinicId}/config`,
      token
    ).then((response) => {
      if (response.status === 200) {
        setConfig(response?.data);
      }
    });
  };

  useEffect(() => {
    setTempMovementId(props.match.params.id);
  }, [props.match.params.id]);
  useEffect(() => {
    if (fileError === "") {
      if (fileToUpload !== null) {
        delayedUploadFile();
      }
      return () => {
        delayedUploadFile.cancel();
      };
    }
  }, [fileToUpload, fileExtension]);
  useEffect(() => {
    if (tempMovementId) {
      setTokenExpired(false);
      getTempMovementDetails(tempMovementId);
    }
  }, [tempMovementId]);

  useEffect(() => {
    const queryString = location?.search;
    const urlParams = new URLSearchParams(queryString);
    const cId = urlParams.get("cid");

    setPClinicId(cId);
  }, [location]);
  useEffect(() => {
    if (tempMovData?.status === "PENDING" && tempMovData?.token) {
      const splitToken = tempMovData?.token?.split(".");
      const decodeToken = atob(splitToken[1]);
      const tokenObj = JSON.parse(decodeToken);
      const currentTime = Math.floor(Date.now() / 1000);
      if (tokenObj.exp > currentTime) {
        setUsername(tempMovData?.username);
        getKeysFromEmail(tempMovData?.email, tempMovData?.token);
      } else {
        setTokenExpired(true);
      }
    } else {
      setTokenExpired(true);
    }
    if (tempMovData) {
      getConfigDetails(tempMovData?.clinicId, tempMovData?.token);
    }
  }, [tempMovData]);
  useEffect(() => {
    if (accessKeyId && secretAccessKey) {
      const region = process.env.REACT_APP_REGION;
      const client = new S3Client({
        region,
        credentials: {
          accessKeyId,
          secretAccessKey,
        },
      });
      setS3Client(client);
    }
  }, [accessKeyId, secretAccessKey]);
  useEffect(() => {
    if (
      config?.skin?.BrandColor !== undefined &&
      config?.skin?.BrandColor !== null
    ) {
      applySkinColor(config);
      setConfigure(config);
    }
  }, [config]);
  return (
    <>
      <Header username={username} logoConfig={configure} />
      <Container className="qr_fileupload_page mt-5">
        {loadingPage ? (
          <Loader
            type="ThreeDots"
            color="var(--primaryColor)"
            height={40}
            width={40}
            className="graph-laoder"
          />
        ) : tokenExpired ? (
          <Row>
            <Col>
              <div>
                The link has expired. Please close the window or try scanning
                another QR code.
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                <label className="customex-mainhead">New Exercise</label>
              </Col>
            </Row>
            {sucessMsg ? (
              <APIMessage
                id={"alert-libconsole"}
                message={sucessMsg}
                className=""
              />
            ) : (
              <>
                <LoadingOverlay show={tempUploading} />
                <Row className="">
                  <Col>
                    <Row>
                      <Col md={12} className="p-2">
                        {msg ? (
                          <Alert
                            severity="error"
                            color="error"
                            className="mb-1 p-0 justify-content-center"
                          >
                            {msg}
                          </Alert>
                        ) : null}
                        {fileError && fileError?.length > 0 ? (
                          <div className="alert alert-danger mt-1" role="alert">
                            {fileError}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="customex-upload-section">
                        <Row>
                          <Col className="d-flex flex-column gap-3">
                            <div className="mb-5">
                              <Form.Control
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleFileChange}
                                accept="image/*,video/*"
                                style={{ display: "none" }}
                              />
                              <div
                                className="qrcCustomex-upload-btn"
                                onClick={handleUploadBtnClicked}
                              >
                                <svg
                                  width="26"
                                  height="26"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_1704_6349)">
                                    <path
                                      d="M18.6667 2.33325L24.5 8.16658V24.5092C24.4997 24.8163 24.3775 25.1107 24.1603 25.3277C23.943 25.5447 23.6486 25.6666 23.3415 25.6666H4.6585C4.35211 25.6644 4.05885 25.5419 3.84209 25.3253C3.62532 25.1088 3.50244 24.8156 3.5 24.5092V3.49059C3.5 2.85125 4.01917 2.33325 4.6585 2.33325H18.6667ZM15.1667 13.9999H18.6667L14 9.33325L9.33333 13.9999H12.8333V18.6666H15.1667V13.9999Z"
                                      fill="currentColor"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1704_6349">
                                      <rect
                                        width="26"
                                        height="26"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                File Upload
                              </div>
                              <div className="qrcCustomExe-label">
                                {tempUpldFileName && fileToUploadcheck ? (
                                  <p>
                                    {tempUpldFileName.length > 10
                                      ? `${tempUpldFileName.slice(
                                          0,
                                          10
                                        )}...${tempUpldFileName.slice(-3)}`
                                      : tempUpldFileName}
                                    <span
                                      className="close-cross"
                                      onClick={() =>
                                        setFileToUploadCheck(false)
                                      }
                                    >
                                      &#10006;
                                    </span>
                                  </p>
                                ) : (
                                  ""
                                )}
                                <span>
                                  Max file size is 500mb
                                  <br />
                                  Only .png, .jpg, .mp4, .mov files are
                                  supported
                                </span>
                              </div>
                            </div>

                            <div className="customex-upload-buttons mt-5">
                              <button
                                id="left-btn-cancel"
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={deleteTempMovementDetails}
                              >
                                Cancel
                              </button>

                              <button
                                id="right-btn-save"
                                type="button"
                                className="btn btn-primary"
                                onClick={checkMovementAvail}
                                disabled={saveLoading}
                              >
                                {saveLoading ? "Saving..." : "Save"}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default FileUploadFrmQR;
