import React, { useEffect, useState } from "react";
import IdleTimer from "react-idle-timer";
import { useSelector } from "react-redux";

const IDEAL_TIMEOUT_INIT = Number(process.env.REACT_APP_IDEAL_TIMEOUT_INIT);
const IdleTimerComponent = ({
  handleOnAction,
  handleOnIdle,
  handleOnActive,
  idleTimerRef,
}) => {
  const timerOut = useSelector((state) => state.user.timerOut);
  const [timeout, setTimeout] = useState(null);
  useEffect(() => {
    setTimeout(IDEAL_TIMEOUT_INIT);
  }, []);

  useEffect(() => {
    if (timerOut !== null) {
      setTimeout(timerOut);
    }
  }, [timerOut]);

  useEffect(() => {
    idleTimerRef.current.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  return (
    <IdleTimer
      ref={idleTimerRef}
      timeout={timeout}
      onAction={handleOnAction}
      onIdle={handleOnIdle}
      onActive={handleOnActive}
      debounce={250}
    />
  );
};

export default IdleTimerComponent;
